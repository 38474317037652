import { DefaultSeo, LocalBusinessJsonLd, LogoJsonLd } from "next-seo";
import Head from "next/head";
import {useTranslations} from 'next-intl';
import Footer from "./Footer";
import Header from "./Header";
import { useRouter } from "next/router";

type Props = {
  children: React.ReactNode;
};

const Layout = ({ children }: Props) => {
  const t = useTranslations("Layout");
  const router = useRouter();

  return (
    <div className="subpixel-antialiased bg-white">
      <Head>
        <link rel="shortcut icon" href="/favicon.ico" type="image/x-icon" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/apple-touch-icon-57x57.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/apple-touch-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/apple-touch-icon-76x76.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/apple-touch-icon-114x114.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/apple-touch-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/apple-touch-icon-144x144.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/apple-touch-icon-152x152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon-180x180.png"
        />
        <meta name="referrer" content="origin" />
      </Head>
      <DefaultSeo
        title={t("title")}
        openGraph={{
          type: "website",
          locale: router.locale,
          url: "https://www.gsalawpartners.com/",
          site_name: t("title"),
          description: t("description"),
        }}
      />
      <LogoJsonLd
        logo="https://www.gsalawpartners.com/logo.jpg"
        url="https://www.gsalawpartners.com"
      />
      <LocalBusinessJsonLd
        type="LegalService"
        id="http://www.gsalawpartners.com/"
        name={t("title")}
        description={t("description")}
        url="https://www.gsalawpartners.com/"
        telephone="+902164460303"
        address={{
          streetAddress: "Bağdat Cd. No:233 K:3 D:5",
          addressLocality: "Göztepe",
          addressRegion: "İstanbul",
          postalCode: "34730",
          addressCountry: "TR",
        }}
        geo={{
          latitude: "40.97183648300698",
          longitude: "29.0598022695708",
        }}
        images={["https://www.gsalawpartners.com/logo.png"]}
        openingHours={[
          {
            opens: "09:00",
            closes: "18:30",
            dayOfWeek: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
          },
        ]}
      />
      <Header />
      {children}
      <Footer />
    </div>
  );
};

export default Layout;
