import type { GetStaticProps, NextPage } from "next";
import AboutUs from "../components/AboutUs";
import ContactUs from "../components/ContactUs";
import Layout from "../components/Layout";
import Partners from "../components/Partners";
import Services from "../components/Services";
import Team from "../components/Team";

const Home: NextPage = () => {
  return (
    <Layout>
      <main>
        <AboutUs />
        <Services />
        <Team />
        {/* <Partners /> */}
        <ContactUs />
      </main>
    </Layout>
  );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      messages: (await import(`../messages/${locale}.json`)).default,
    },
  };
};

export default Home;
