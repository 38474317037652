import { useTranslations } from "next-intl";
import Image from "next/image";
import AboutUsImage1 from "../public/about-us-1.jpg";
import AboutUsImage2 from "../public/about-us-2.jpg";
import AboutUsImage3 from "../public/about-us-3.jpg";

const AboutUs = () => {
  const t = useTranslations("AboutUs");

  return (
    <>
      <section className="py-24 overflow-hidden bg-white md:py-32" id="about">
        <div className="container px-4 mx-auto">
          <div className="flex flex-wrap -mx-4 lg:items-center">
            <div className="w-full px-4 mb-16 md:w-1/2 md:mb-0">
              <div className="relative mx-auto md:ml-0 max-w-max">
                <Image
                  src={AboutUsImage1}
                  width={720}
                  height={450}
                  alt="Adalet Heykeli"
                  placeholder="blur"
                />
              </div>
            </div>
            <div className="w-full px-4 md:w-1/2">
              <span className="inline-block px-2 py-px mb-4 text-xs font-medium leading-5 uppercase rounded-full shadow-sm text-secondary bg-primary">
                {t("subtitle")}
              </span>
              <h1 className="mb-8 font-serif text-4xl leading-tight tracking-tighter md:text-5xl text-coolGray-900">
                {t("title")}
              </h1>
              <p className="mb-6 text-lg text-justify md:text-xl text-coolGray-500">
                {t("howWeWork")}
              </p>
              <a
                className="inline-block w-full px-5 py-4 text-base font-medium leading-4 text-center rounded-md shadow-sm bg-secondary md:w-auto text-blue-50 hover:bg-primary hover:text-secondary focus:ring-2 focus:ring-primary focus:ring-opacity-50"
                href="#contact"
              >
                {t("contactUs")}
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="py-24 overflow-hidden bg-white md:py-32">
        <div className="container px-4 mx-auto">
          <div className="flex flex-col-reverse flex-wrap -mx-4 md:flex-row lg:items-center">
            <div className="w-full px-4 mb-16 md:w-1/2 md:mb-0">
              <span className="inline-block px-2 py-px mb-4 text-xs font-medium leading-5 uppercase rounded-full shadow-sm text-secondary bg-primary">
                {t("subtitle2")}
              </span>
              <h1 className="mb-8 font-serif text-4xl leading-tight tracking-tighter md:text-5xl text-coolGray-900">
                {t("title2")}
              </h1>
              <p className="mb-6 text-lg text-justify md:text-xl text-coolGray-500">
                {t("whatWeDo1")}
              </p>
              <p className="mb-6 text-lg text-justify md:text-xl text-coolGray-500">
                {t("whatWeDo2")}
              </p>
              <p className="mb-6 text-lg text-justify md:text-xl text-coolGray-500">
                {t("whatWeDo3")}
              </p>
              <a
                className="inline-block w-full px-5 py-4 text-base font-medium leading-4 text-center rounded-md shadow-sm bg-secondary md:w-auto text-blue-50 hover:bg-primary hover:text-secondary focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                href="#contact"
              >
                {t("contactUs")}
              </a>
            </div>
            <div className="w-full h-full px-4 md:w-1/2">
              <div className="relative mx-auto md:ml-0 max-w-max">
                <Image
                  src={AboutUsImage2}
                  width={720}
                  height={450}
                  alt="Çalışma Görseli"
                  placeholder="blur"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-24 overflow-hidden bg-white md:py-32" id="about">
        <div className="container px-4 mx-auto">
          <div className="flex flex-wrap -mx-4 lg:items-center">
            <div className="w-full px-4 mb-16 md:w-1/2 md:mb-0">
              <div className="relative mx-auto md:ml-0 max-w-max">
                <Image
                  src={AboutUsImage3}
                  width={720}
                  height={450}
                  alt="Adalet Heykeli"
                  placeholder="blur"
                />
              </div>
            </div>
            <div className="w-full px-4 md:w-1/2">
              <span className="inline-block px-2 py-px mb-4 text-xs font-medium leading-5 uppercase rounded-full shadow-sm text-secondary bg-primary">
                {t("subtitle3")}
              </span>
              <h1 className="mb-8 font-serif text-4xl leading-tight tracking-tighter md:text-5xl text-coolGray-900">
                {t("title3")}
              </h1>
              <p className="mb-6 text-lg text-justify md:text-xl text-coolGray-500">
                {t("ombudsman")}
              </p>
              <a
                className="inline-block w-full px-5 py-4 text-base font-medium leading-4 text-center rounded-md shadow-sm bg-secondary md:w-auto text-blue-50 hover:bg-primary hover:text-secondary focus:ring-2 focus:ring-primary focus:ring-opacity-50"
                href="#contact"
              >
                {t("contactUs")}
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
