import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faEnvelopeSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslations } from "next-intl";

const Team = () => {
  const t = useTranslations("Team");

  return (
    <section className="py-24 bg-white" id="team">
      <div className="container px-4 mx-auto">
        <div className="mb-16 text-center">
          <span className="inline-block px-2 py-px mb-4 text-xs font-medium leading-5 uppercase rounded-full text-secondary bg-primary rounded-9xl">
            {t("subtitle")}
          </span>
          <h3 className="mb-4 font-serif text-3xl leading-tight tracking-tighter md:text-5xl text-coolGray-900">
            {t("title")}
          </h3>
          <p className="mb-8 text-lg text-justify xl:mx-20 md:text-xl text-coolGray-500">
            {t("description")}
          </p>
        </div>
        <div className="flex flex-wrap -mx-4">
          <div className="w-full px-4 mb-10 md:w-1/2 lg:w-1/3">
            <div className="h-full px-10 py-8 text-center rounded-md bg-coolGray-50">
              <h3 className="mb-2 font-serif text-2xl leading-tight md:text-3xl">
                Ümmü Gülsüm Bilen
              </h3>
              <span className="inline-block mb-6 text-lg font-medium text-primary">
                {t("lawyerOmbudsman")}
              </span>
              <div className="flex items-center justify-center">
                <div className="inline-block mr-6 hover:opacity-80">
                  <a
                    href="https://www.linkedin.com/in/%C3%BCmm%C3%BC-g%C3%BCls%C3%BCm-bilen-4b227b14b/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      className="text-4xl hover:text-primary"
                      icon={faLinkedin}
                    />
                  </a>
                </div>
                <a
                  className="inline-block hover:opacity-80"
                  href="mailto:gulsum@gsalawpartners.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon
                    className="text-4xl hover:text-primary"
                    icon={faEnvelopeSquare}
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="w-full px-4 mb-10 md:w-1/2 lg:w-1/3">
            <div className="h-full px-10 py-8 text-center rounded-md bg-coolGray-50">
              <h3 className="mb-2 font-serif text-2xl leading-tight md:text-3xl">
                Sinan Cem Ünlü
              </h3>
              <span className="inline-block mb-6 text-lg font-medium text-primary">
                {t("lawyerLlm")}
              </span>
              <div className="flex items-center justify-center">
                <div className="inline-block mr-6 hover:opacity-80">
                  <a
                    href="https://www.linkedin.com/in/sinan-cem-unlu-ll-m-6151aa80/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      className="text-4xl hover:text-primary"
                      icon={faLinkedin}
                    />
                  </a>
                </div>
                <a
                  className="inline-block hover:opacity-80"
                  href="mailto:sinan@gsalawpartners.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon
                    className="text-4xl hover:text-primary"
                    icon={faEnvelopeSquare}
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="w-full px-4 mb-10 md:w-1/2 lg:w-1/3">
            <div className="h-full px-10 py-8 text-center rounded-md bg-coolGray-50">
              <h3 className="mb-2 font-serif text-2xl leading-tight md:text-3xl">
                Aysel Karaca
              </h3>
              <span className="inline-block mb-6 text-lg font-medium text-primary">
                {t("lawyerOmbudsman")}
              </span>
              <div className="flex items-center justify-center">
                <div className="inline-block mr-6 hover:opacity-80">
                  <a
                    href="https://www.linkedin.com/in/aysel-akg%C3%BCn-0a7511150/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      className="text-4xl hover:text-primary"
                      icon={faLinkedin}
                    />
                  </a>
                </div>
                <a
                  className="inline-block hover:opacity-80"
                  href="mailto:aysel@gsalawpartners.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon
                    className="text-4xl hover:text-primary"
                    icon={faEnvelopeSquare}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container px-4 mx-auto">
        <div className="flex flex-wrap justify-center -mx-4">
          <div className="w-full px-4 mb-10 md:w-1/2 lg:w-1/3">
            <div className="h-full px-10 py-8 text-center rounded-md bg-coolGray-50">
              <h3 className="mb-2 font-serif text-2xl leading-tight md:text-3xl">
                Sena Çilingir
              </h3>
              <span className="inline-block mb-6 text-lg font-medium text-primary">
                {t("lawyer")}
              </span>
              <div className="flex items-center justify-center">
                <div className="inline-block mr-6 hover:opacity-80">
                  <a
                    href="https://www.linkedin.com/in/sena-%C3%A7ilingir-277001224/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      className="text-4xl hover:text-primary"
                      icon={faLinkedin}
                    />
                  </a>
                </div>
                <a
                  className="inline-block hover:opacity-80"
                  href="mailto:sena@gsalawpartners.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon
                    className="text-4xl hover:text-primary"
                    icon={faEnvelopeSquare}
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="w-full px-4 mb-10 md:w-1/2 lg:w-1/3">
            <div className="h-full px-10 py-8 text-center rounded-md bg-coolGray-50">
              <h3 className="mb-2 font-serif text-2xl leading-tight md:text-3xl">
                Mustafa Uzunlu
              </h3>
              <span className="inline-block mb-6 text-lg font-medium text-primary">
                {t("intern")}
              </span>
              <div className="flex items-center justify-center">
                {/* <div className="inline-block mr-6 hover:opacity-80">
                  <a
                    href="https://www.linkedin.com/in/sena-%C3%A7ilingir-277001224/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      className="text-4xl hover:text-primary"
                      icon={faLinkedin}
                    />
                  </a>
                </div> */}
                <a
                  className="inline-block hover:opacity-80"
                  href="mailto:info@gsalawpartners.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon
                    className="text-4xl hover:text-primary"
                    icon={faEnvelopeSquare}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Team;
