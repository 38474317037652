import { useTranslations } from "next-intl";
import Image from "next/image";
import logoPic from "../public/logo.png";

const Footer = () => {
  const year = new Date().getFullYear();
  const t = useTranslations("Footer");
  const tLayout = useTranslations("Layout");

  return (
    <footer>
      <div className="container px-4 mx-auto">
        <div className="max-w-4xl pt-24 mx-auto pb-11">
          <div className="block mb-5 md:mx-auto max-w-max">
            <Image
              src={logoPic}
              width={385}
              height={83}
              alt={`${tLayout("title")} Logo`}
              placeholder="blur"
            />
          </div>
        </div>
      </div>
      <div className="border-b border-coolGray-100"></div>
      <div className="container px-4 mx-auto">
        <p className="py-10 text-lg font-medium text-center md:pb-20 md:text-xl text-coolGray-400">
          © {year} {t("copyright")}
        </p>
      </div>
    </footer>
  );
};

export default Footer;
